import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function PlayStore (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M14.16 13.3l-8.07 8.07c.157-.027.314-.074.464-.165l9.655-5.858-2.048-2.048zm3.462-3.462l-2.358 2.358 2.317 2.318 2.173-1.318c.417-.256.653-.64.646-1.06-.006-.413-.242-.782-.643-1.01-.09-.05-.928-.558-2.135-1.288zM4.83 3.968c-.016.09-.03.18-.03.275v15.995c0 .067.013.13.023.194l8.235-8.236L4.83 3.97zM6.066 3l8.095 8.093 2.089-2.089c-2.854-1.731-6.678-4.055-8.397-5.1l-1.152-.697c-.204-.123-.421-.19-.635-.207z"
      />
    </SvgIcon>
  );
};

export default PlayStore;
