import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function Close (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path fill="#4A4A4A" d="M0 0H7129V7282H0z" transform="translate(-839 -3554)"/>
          <g>
            <path fill="#000" d="M0 0H1440V6145H0z" transform="translate(-398 -2890)"/>
            <g>
              <g fill="#FFF">
                <path d="M0 0H362V1024H0z" transform="translate(-398 -2890) translate(70 2797) translate(0 68.5)"/>
              </g>
              <g fill="#4A4A4A">
                <path d="M0 11.25L24 11.25 24 13.5 0 13.5z" transform="translate(-398 -2890) translate(70 2797) translate(328 93) rotate(45 12 12.375)"/>
                <path d="M0 11.25L24 11.25 24 13.5 0 13.5z" transform="translate(-398 -2890) translate(70 2797) translate(328 93) scale(-1 1) rotate(45 0 -16.596)"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Close;
