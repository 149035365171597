import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function Arrow (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M8.693 3.953l7.362 7.238c.46.45.46 1.168 0 1.618l-7.362 7.239c-.233.229-.548.352-.875.352-.303 0-.598-.107-.827-.308l-.094-.093c-.409-.45-.398-1.133.047-1.57L13.484 12l-6.54-6.43c-.477-.467-.455-1.218.047-1.661.486-.428 1.242-.41 1.702.044z"
      />
    </SvgIcon>
  );
};

export default Arrow;
