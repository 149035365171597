import { useLocation } from "@reach/router";

export const BREADCRUMB: Record<string, string> = {
  "company/milestone": "About > Our History",
  "company/news": "About > News",

  //
  business: "",

  // tech
  tech: "",
  "tech/webrtc": "Tech > WebRTC",
  "tech/aiml": "Tech > AI",
  "tech/data": "Tech > Data",
  "tech/ar": "Tech > AR",

  // ir
  ir: "",

  // contact
  contact: "",
};

export const useBreadcrumb = () => {
  const { pathname } = useLocation();
  const breadCrumbKey = pathname.split("/").filter(key => {
    if (key === "ko") return false;
    if (key === "en") return false;
    if (key === "") return false;
    return true;
  });

  const pathnameKey = breadCrumbKey.join("/");
  return BREADCRUMB[pathnameKey] || "";
};

export const useCategoryURL = () => {
  const { pathname } = useLocation();
  const arr = pathname.split("/").filter(item => item !== "");
  if (arr.length < 2) {
    return "company";
  }
  return arr[1];
};
