

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function AppleStore (props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 21 20" {...props}>
      <path d="M15.458 9.74961C15.4762 8.33436 16.2363 6.99522 17.442 6.25395C16.6814 5.16762 15.4073 4.47885 14.0818 4.43738C12.668 4.28899 11.2974 5.28336 10.577 5.28336C9.84265 5.28336 8.73343 4.45211 7.53899 4.47669C5.98209 4.52699 4.53067 5.41216 3.7732 6.77331C2.14495 9.59237 3.35948 13.7354 4.91921 16.0142C5.69957 17.13 6.6116 18.3764 7.80496 18.3322C8.97274 18.2838 9.40888 17.5875 10.8185 17.5875C12.215 17.5875 12.6242 18.3322 13.8418 18.3041C15.0949 18.2837 15.8844 17.1833 16.6373 16.0569C17.198 15.2619 17.6295 14.3831 17.9157 13.4533C16.4431 12.8305 15.4597 11.3485 15.458 9.74961Z"/>
      <path d="M13.1582 2.93878C13.8414 2.11859 14.178 1.06437 14.0965 0C13.0527 0.109634 12.0885 0.608514 11.396 1.39724C10.7189 2.16787 10.3665 3.20354 10.4331 4.22722C11.4773 4.23798 12.5038 3.75262 13.1582 2.93878Z"/>
    </SvgIcon>
  );
};

export default AppleStore;
