import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

function Menu (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        d="M23.294 16.941v2.118H.706V16.94h22.588zm0-6.353v2.118H.706v-2.118h22.588zm0-6.353v2.118H.706V4.235h22.588z"
      />
    </SvgIcon>
  );
};

export default Menu;
